"use client";

import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertCircle, Loader2, Check, ChevronsUpDown } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";

import { countries } from "@cloudifybiz/lighthouse-core/constants";
import { api } from "@/trpc/client";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandList,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  newCompanyFormSchema,
  type NewCompanyFormSchema,
} from "@cloudifybiz/lighthouse-core/zod/schema/onboarding";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { tRPCErrorToUseFormErrors } from "@/utils/misc";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { slugTransformSchema } from "@cloudifybiz/lighthouse-core/zod/schema/misc";

export const NewCompanyForm = ({
  onCancel,
  onCompanyCreate,
}: {
  onCancel?: () => void;
  onCompanyCreate?: () => void;
}) => {
  const newCompanyForm = useForm<NewCompanyFormSchema>({
    resolver: zodResolver(newCompanyFormSchema),
    defaultValues: {
      country: "DK",
      name: "",
      vatin: "",
      slug: "",
    },
  });
  const watchName = useWatch({
    control: newCompanyForm.control,
    name: "name",
    defaultValue: "",
  });
  const [vatFetching, setVatFetching] = useState(false);

  const updateVATData = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const result = await newCompanyForm.trigger(["vatin"]);
      if (result) {
        newCompanyForm.clearErrors("root");
        const vatin = newCompanyForm.getValues("vatin");
        const country = newCompanyForm.getValues("country");
        if (!vatin || !country) return;
        setVatFetching(true);

        try {
          const vat = await api.misc.vatValidation.query({
            country,
            vat: vatin,
          });
          if (vat.valid) {
            vat.name && newCompanyForm.setValue("name", vat.name);
            vat.countryCode &&
              newCompanyForm.setValue("country", vat.countryCode);
          } else {
            newCompanyForm.setError("vatin", {
              message: "Couldn't find a company with this VAT number.",
            });
          }
        } catch (error) {
          tRPCErrorToUseFormErrors<typeof newCompanyForm>(error).forEach(
            (error) => {
              newCompanyForm.setError(...error);
            },
          );
        } finally {
          setVatFetching(false);
        }
      }
    },
    [newCompanyForm],
  );

  useEffect(() => {
    if (watchName) {
      const result = slugTransformSchema.safeParse(watchName);
      if (result.success) return newCompanyForm.setValue("slug", result.data);
    }
  }, [newCompanyForm, watchName]);

  const onFormSubmit = useCallback(
    async (values: NewCompanyFormSchema) => {
      try {
        await api.company.create.mutate({
          name: values.name,
          slug: values.slug,
          vatin: values.vatin.startsWith(values.country)
            ? values.vatin
            : `${values.country}${values.vatin}`,
        });
        onCompanyCreate && onCompanyCreate();
      } catch (error) {
        tRPCErrorToUseFormErrors<typeof newCompanyForm>(error).forEach(
          (error) => {
            newCompanyForm.setError(...error);
          },
        );
      }
    },
    [newCompanyForm, onCompanyCreate],
  );

  return (
    <Form {...newCompanyForm}>
      <form
        onSubmit={newCompanyForm.handleSubmit(onFormSubmit)}
        className="m-auto w-11/12 space-y-5"
      >
        <FormField
          key="vatin"
          control={newCompanyForm.control}
          name="vatin"
          render={({ field }) => (
            <div className="space-y-2">
              <div className="flex flex-row items-end gap-2">
                <div className="flex-1">
                  <FormItem>
                    <FormLabel>VAT Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter the VAT number" {...field} />
                    </FormControl>
                  </FormItem>
                </div>
                <Button
                  type="button"
                  onClick={updateVATData}
                  className="w-20"
                  disabled={
                    vatFetching || newCompanyForm.formState.isSubmitting
                  }
                >
                  {vatFetching ? (
                    <Loader2 className="mx-auto h-4 w-4 animate-spin" />
                  ) : (
                    "Search"
                  )}
                </Button>
              </div>
              <FormMessage />
            </div>
          )}
        />
        <FormField
          key="country"
          control={newCompanyForm.control}
          name="country"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Country</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className="w-full justify-between"
                    >
                      {field.value
                        ? countries
                            .find((country) => country.value === field.value)
                            ?.text.toString()
                        : "Select Country"}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command className="bg-opacity-0">
                    <CommandInput placeholder="Search country..." />
                    <CommandEmpty>No country found.</CommandEmpty>
                    <CommandList>
                      {countries.map((country) => (
                        <CommandItem
                          value={country.text}
                          key={country.value}
                          onSelect={() => {
                            newCompanyForm.setValue("country", country.value);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              country.value === field.value
                                ? "opacity-100"
                                : "opacity-0",
                            )}
                          />
                          {country.text}
                        </CommandItem>
                      ))}
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          key="name"
          control={newCompanyForm.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company Name</FormLabel>
              <FormControl>
                <Input
                  key={field.name}
                  placeholder="Enter the company name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          key="slug"
          control={newCompanyForm.control}
          name="slug"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company Unique Identifier</FormLabel>
              <FormControl>
                <Input
                  key={field.name}
                  placeholder="unique-company-identifier"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {newCompanyForm.formState.errors.root?.message && (
          <Alert
            variant="destructive"
            id="root-error-message"
            className="mx-auto"
          >
            <AlertCircle className="mr-2 h-4 w-4" />
            <AlertTitle>
              {newCompanyForm.formState.errors.root?.message}
            </AlertTitle>
          </Alert>
        )}
        <div className="flex w-full justify-end gap-2">
          {onCancel && (
            <Button type="button" variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button type="submit">
            {newCompanyForm.formState.isSubmitting && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Create Company
          </Button>
        </div>
      </form>
    </Form>
  );
};
