import * as z from "zod";

import { countriesEnum } from "@cloudifybiz/lighthouse-core/constants";
import { emailSchema } from "@cloudifybiz/lighthouse-core/zod/schema/auth";
import { slugSchema } from "@cloudifybiz/lighthouse-core/zod/schema/misc";

export const newCompanyFormSchema = z.object({
  vatin: z
    .string({
      invalid_type_error: "VAT number needs to be a string",
      required_error: "VAT number is required",
    })
    .max(30, "VAT Number is too long"),
  name: z
    .string({
      invalid_type_error: "Company Name needs to be a string",
      required_error: "Company Name is required",
    })
    .max(255, "Company Name is too long"),
  slug: slugSchema,
  country: z.enum(countriesEnum),
});

export type NewCompanyFormSchema = z.infer<typeof newCompanyFormSchema>;

export const billingInformationFormSchema = z.object({
  name: z
    .string({
      invalid_type_error: "Billing Name needs to be a string",
      required_error: "Billing Name is required",
    })
    .max(255, "Billing Name is too long"),
  emailAddress: emailSchema.nullish(),
  phoneNumber: z
    .string({
      invalid_type_error: "Billing phone number needs to be a string",
      required_error: "Billing phone number is required",
    })
    .max(30, "Billing Phone Number is too long")
    .nullish(),
  streetAddress: z.string({
    invalid_type_error: "Street Address needs to be a string",
    required_error: "Street Address is required",
  }),
  city: z
    .string({
      invalid_type_error: "City needs to be a string",
      required_error: "City number is required",
    })
    .max(255, "City is too long"),
  state: z
    .string({
      invalid_type_error: "State needs to be a string",
      required_error: "State is required",
    })
    .max(255, "State is too long"),
  zip: z
    .string({
      invalid_type_error: "Zip Code needs to be a string",
      required_error: "Zip Code is required",
    })
    .max(20, "Zip Code is too long"),
});

export type BillingInformationFormSchema = z.infer<
  typeof billingInformationFormSchema
>;

export const addressSchema = z.object({
  original: z.string().optional(),
  housenumber: z.union([z.string(), z.number()]).optional(),
  street: z.string().optional(),
  postcode: z.union([z.string(), z.number()]).optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
});

export const vatResponseSchema = z.object({
  countryCode: z.string().optional(),
  requestDate: z
    .string()
    .optional()
    .transform((date) => (date ? new Date(date) : undefined)),
  valid: z.boolean().optional(),
  vatNumber: z.preprocess((val) => {
    if (typeof val === "number") return val.toString();
    return val;
  }, z.string().optional()),
  name: z.string().optional(),
  address: addressSchema.optional(),
  email: z.string().optional(),
});

export const onboardingParamsSchema = z.object({
  plan: z.object({
    slug: z.string(),
    currency: z.string(),
    annual: z
      .union([z.boolean(), z.string()])

      .transform((v) => v === "true" || v === true),
  }),
  workflows: z.array(z.string()),
});

export const onboardingParamsSchemaOptional =
  onboardingParamsSchema.deepPartial();

export const onboardingParamsWithFiltersSchema =
  onboardingParamsSchemaOptional.extend({
    filters: z
      .object({
        apps: z.array(z.string()).optional(),
        search: z.string().optional(),
      })
      .optional(),
  });
export const bookMeetingFailedSchema = z.object({
  meetingBookFailed: z.boolean(),
  meetingsPayload: z.object({
    linkType: z.string(),
    offline: z.boolean(),
    userSlug: z.string(),
    formGuid: z.string(),
    bookingResponse: z.object({
      event: z.object({
        duration: z.number(),
        formFields: z.array(z.unknown()),
        dateString: z.string(),
        dateTime: z.number(),
      }),
      postResponse: z.null(),
    }),
    isPaidMeeting: z.boolean(),
  }),
});

export const bookMeetingSuccessSchema = z.object({
  meetingBookSucceeded: z.boolean(),
  meetingsPayload: z.object({
    linkType: z.string(),
    offline: z.boolean(),
    userSlug: z.string(),
    formGuid: z.string(),
    bookingResponse: z.object({
      event: z.object({
        duration: z.number(),
        formFields: z.array(z.unknown()),
        dateString: z.string(),
        dateTime: z.number(),
      }),
      postResponse: z.object({
        timerange: z.object({ start: z.number(), end: z.number() }),
        organizer: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          fullName: z.string(),
          name: z.string(),
          userId: z.null(),
        }),
        bookedOffline: z.boolean(),
        contact: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          fullName: z.string(),
          name: z.string(),
          userId: z.null(),
        }),
      }),
    }),
    isPaidMeeting: z.boolean(),
  }),
});

export const bookMeetingEventSchema = z.union([
  bookMeetingFailedSchema,
  bookMeetingSuccessSchema,
]);
